export default {
    "landing": {
        "hero": {
            "title": "Sail the seas together.",
            "description": "Real-time syncronized playback. Optional votin' system. Dark theme. No sign up required. All Open Source. It's ne'er been easier t' watch videos together.",
            "btns": {
                "create": "Create Room",
                "browse": "Browse Rooms",
                "source": "View Source",
            },
        },
    },
};
